import React from "react";
import Drawer from "react-drag-drawer";
import "./index.css";
import {
    Button,
    ButtonGroup,
    Container,
    ButtonToolbar,
    Jumbotron,
    Card
} from "react-bootstrap";
import Undraw from "react-undraw";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from 'react-datetime-picker';
import { FaCalendarAlt } from 'react-icons/fa';
export default class DialogBoxs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extendValue: 30,
            isShowWarningForExtend: false,
            minDate:new Date(),
        }
    }
    isShowLowBalance(price) {
        var req = parseInt(price);
        var bal = parseInt(this.props.lcoBalance);
        if (req > bal) {
            return (
                <div>
                    <label style={{ fontWeight: "bold", color: "red" }}>Low Balance !!</label>
                </div>
            );
        } else {
            return null;
        }
    }
    calculateTheDays(e){
        console.log(e);
        var today = new Date();
        var endDate = new Date(e);
       var remaining = Math.ceil((endDate-today)/(1000 * 60 * 60 * 24));
        console.log("remaining",remaining)
        this.setState({ extendValue: remaining }) 
    
    
      }
    render() {
        var price = ((this.props.selectedAmount / 30) * this.state.extendValue).toFixed(2);
        var val = price * 0.18;
        price = parseInt(val) + parseInt(price);
        return (
            <div>





                <Drawer

                    open={this.props.isShowExtendDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            {this.isShowLowBalance(price)}
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>  Extends</div>
                            <div style={{ fontSize: 13, marginTop: 10 }}>How many day<span style={{ color: "red", fontWeight: "bold" }}> extends </span> ?</div>
                            <div style={{ textAlign: "end", marginRight: 15 }}>Amount : <span style={{ fontWeight: "bold", color: "red" }}>{'\u20B9'} {price}</span></div>
                            <div>
                            <input type="number" value={this.state.extendValue}
                                onChange={(e) => { this.setState({ extendValue: e.target.value }) }}
                                style={{ width: "40vh", marginTop: 10, border: "2px solid gray", padding: 5, }}
                            />
                             <DatePicker
                 
                 onChange={(e) => { 
                   this.calculateTheDays(e);
                   console.log(e) }}
                 minDate={this.state.minDate}
                 customInput={<FaCalendarAlt
                   // onClick={this.handleIconClick}
                   style={{ cursor: 'pointer', fontSize: '24px',margin:10 }}
                   title="Open Calendar"
                 />} // Hide the default input field
wrapperClassName="icon-only-datepicker"
                 />
                            </div>
                            <div style={{ marginTop: 15, textAlign: "end" }}>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red" }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.hindExtendDialog();

                                    }}
                                >cancel</Button>
                                <Button
                                    style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.setState({ isShowWarningForExtend: true });

                                    }}
                                >OK</Button>
                            </div>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.state.isShowWarningForExtend}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>  Extends</div>
                            <div style={{ fontSize: 13, marginLeft: 36, marginBottom: 20, marginTop: 5 }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>Extend</span> ?</div>
                            <Undraw
                                style={{ marginTop: 10, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: 79 }}
                                name="add-files" />

                            <Button
                                style={{ backgroundColor: "red", borderColor: "red" }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({ isShowWarningForExtend: false });

                                }}
                            >cancel</Button>
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({ isShowWarningForExtend: false });
                                    this.props.hindExtendDialog();
                                    this.props.getExtendFunc(this.state.extendValue);

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isShowResumeWarning}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>  Resume</div>
                            <div style={{ fontSize: 13, marginLeft: 36, marginBottom: 20, marginTop: 5 }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>Resume</span> ?</div>
                            <Undraw
                                primaryColor="green"
                                style={{ marginTop: 10, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: 79 }}
                                name="add-files" />

                            <Button
                                style={{ backgroundColor: "red", borderColor: "red" }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindResumeWarning()

                                }}
                            >cancel</Button>
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindResumeWarning();
                                    this.props.resumeCustomer();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.props.isShowMantainanceDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">


                            <label style={{ fontSize: 20, fontWeight: "bold", textAlign: "center", marginLeft: 20 }}>{this.props.maintText} </label>
                            <div style={{ width: 350, height: 250, marginBottom: 30 }}>
                                <img

                                    style={{ width: 350, height: 250 }}
                                    src={this.props.maintIMG} className="spinnerContent" color="green" />
                            </div>
                            <div style={{ display: "block", textAlign: "center", width: "100%" }}>
                                <Button
                                    style={{ backgroundColor: "#E64A19", borderColor: "#E64A19" }}
                                    // className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.getLOC_Details();


                                    }}
                                >Refresh</Button>

                            </div>

                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer
          open={this.props.isShowLowBalanceDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 20, fontWeight: "bold" }}>Low Balance</div>
              <Undraw
                primaryColor="red"
                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                className="wallet" name="wallet" />

              
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                 this.props.hindLowBalanceDialog()
                }}
              >
                OK
              </Button>


            </Card.Body>
          </Card>
        </Drawer>


            </div>
        );
    }
}
